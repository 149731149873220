<template>
  <div id="estimate-form" :class="`py-[30px] md:px-[30px] h-auto opacity-95 bg-white rounded-[16.54px] backdrop-blur-[135.65px] ${singlePage ? 'max-w-lg mx-auto m-auto' : 'max-w-xl md:mx-[20px] mb-[80px]'}`">
    <div class="text-[#151313] lg:text-3xl text-2xl lg:text-left text-center font-montserrat font-medium leading-tight break-words">
      {{ getTitle() }}
    </div>
    <div class="w-full lg:my-[15px] my-[10px]">
      <!-- Zip -->
      <UForm v-if="state.page === 0" :schema="zipSchema" :state="state" class="lg:space-y-4 space-y-0 px-3" @submit="onSubmitZip">
        <UFormGroup name="zip" class="w-full" :error="state.zipError">
          <UInput
            v-model="state.zip"
            type="text"
            placeholder="Zip"
            :disabled="state.loading"
            class="appearance-none form-input-style"
            @keypress="onZipNumberKeypress"
          />
        </UFormGroup>
        <div class="flex lg:justify-start justify-center mt-4 ">
          <ButtonForm text="Get Estimate" :disabled="state.loading" />
        </div>
      </UForm>

      <!-- First Name, Last Name, Address -->
      <UForm
        v-if="state.page === 1"
        ref="form"
        :schema="secondSchema"
        :state="state"
        class="lg:space-y-4 space-y-0 px-3"
        @submit="onNext"
      >
        <div class="flex flex-wrap">
          <UFormGroup name="firstName" class="w-full md:w-1/2 md:pr-2">
            <UInput
              v-model="state.firstName"
              type="text"
              placeholder="First Name"
              class="appearance-none form-input-style"
            />
          </UFormGroup>

          <UFormGroup name="lastName" class="w-full md:w-1/2 md:pl-2">
            <UInput
              v-model="state.lastName"
              type="text"
              placeholder="Last Name"
              class="appearance-none form-input-style"
            />
          </UFormGroup>
        </div>

        <UFormGroup
          name="address"
          class="w-full"
          :help="`${state.city}, ${state.state} ${state.zip}`"
          :error="state.zipError"
          @keypress="onAddressKeyPress"
        >
          <div class="relative appearance-none form-input-style">
            <CommonAutoComplete v-model="addressFields" placeholder="Full Address" @update:address="handleAddressUpdate" />
          </div>
        </UFormGroup>

        <div class="flex lg:justify-start justify-center mt-4">
          <ButtonForm text="Next" />
        </div>
      </UForm>

      <!-- Roof Type -->
      <UForm v-if="state.page === 2" :schema="secondSchema" :state="state" class="lg:space-y-4 space-y-0 px-3" @submit="onNext">
        <UFormGroup v-for="roofType in roofTypes" :key="roofType" class="mb-5">
          <UButton
            color="white"
            class="justify-start"
            :class="{
              'ring-blue-500': state.roofType === roofType,
              'text-blue-500': state.roofType === roofType,
              'ring-gray-200': state.roofType !== roofType
            }"
            value="roofType"
            size="xl"
            block
            @click="state.roofType = roofType"
          >
            <template #leading>
              <img v-lazy-load 
                :style=" state.roofType === roofType ? 'filter:invert(35%) sepia(84%) saturate(5601%) hue-rotate(181deg) brightness(96%) contrast(101%)' : ''"
                :data-src="`/${roofType.toLowerCase()}-roof.svg`"
              >
            </template>
            {{ roofType }}
          </UButton>
        </UFormGroup>
        <div class="flex lg:justify-start justify-center mt-4">
          <ButtonForm text="Next" />
        </div>
      </UForm>

      <!-- Email, Phone, Agree -->
      <UForm v-if="state.page === 3" :schema="schema" :state="state" class="lg:space-y-4 space-y-0 px-3" @submit="onNext">
        <div class="flex flex-wrap">
          <UFormGroup name="email" class="w-full">
            <UInput
              v-model="state.email"
              type="email"
              placeholder="Email"
              class="appearance-none form-input-style"
            />
          </UFormGroup>

          <UFormGroup name="phone" class="w-full">
            <UInput
              v-model="state.phone"
              type="tel"
              :disabled="state.loading"
              placeholder="Phone Number"
              class="appearance-none form-input-style"
              @keypress="onPhoneNumberKeypress"
            />
          </UFormGroup>
        </div>

        <UFormGroup name="agree" class="w-full pb-[15px]">
          <label class="flex items-start space-x-2">
            <input
              v-model="state.agree"
              type="checkbox"
              class="form-checkbox h-5 w-5 text-blue-600"
            >
            <span class="text-sm">
              By submitting your info on this form, you are agreeing to be contacted regarding your service request by means of telephone, email, or text including using pre-recorded or auto dialed phone calls or text messages to the phone number you have provided, including your wireless number, if provided. Consent to contact doesn't require you to purchase service. By using this service, you agree to our
              <NuxtLink to="/terms-and-conditions" target="_blank">
                Terms Of Service
              </NuxtLink>
              and
              <NuxtLink to="/privacy-policy" target="_blank">
                Privacy Policy
              </NuxtLink>
              .
            </span>
          </label>
        </UFormGroup>

        <div class="flex lg:justify-start justify-center mt-4">
          <ButtonForm :icon="true" :disabled="state.loading" />
        </div>
      </UForm>
      <div class="flex lg:justify-start justify-center mt-1">
        <div v-if="state.error" class="mt-2 text-red-500 dark:text-red-400 text-sm">
          {{ state.errorMessage }}
        </div>
      </div>
    </div>

    <div v-if="state.page > 0" class="w-full max-w-lg mt-5 px-5">
      <div class="flex flex-row space-x-3">
        <div
          v-for="i in 3"
          :key="i"
          class="h-2 flex-grow rounded"
          :class="{
            'bg-blue-500': state.page === i,
            'bg-gray-200': state.page !== i
          }"
        />
      </div>
      <UButton
        class="mt-5"
        color="white"
        variant="ghost"
        :disabled="state.loading"
        :padded="false"
        icon="i-heroicons-chevron-left"
        @click="state.page--"
      >
        Back
      </UButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { object, string, boolean, type InferType } from 'yup'
import ButtonForm from './ButtonForm.vue'
import { validZipCodes } from '~/utils'
const roofTypes = ['Metal', 'Asphalt', 'Other']
const route = useRoute()
const zip = route.query.zip as string
const form = ref(null)

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: () => 'Don’t Wait — Schedule Your Free Roof Estimate Today!'
  },
  singlePage: {
    type: Boolean,
    required: false,
    default: false
  }
})
const zipSchema = object({
  zip: string().required('Please enter your zip.').matches(/^\d+$/, 'Zip code must be digits')
})

const getTitle = () => {
  switch (state.page) {
    case 1:
      return 'Complete the Form for Your Free Estimate'
    case 2:
      return 'What roof type are you interested in?'
    case 3:
      return 'Complete the Form for Your Free Estimate'
    default:
      return props.title
  }
}

const secondSchema = object({
  firstName: string().required('Please enter first name.'),
  lastName: string().required('Please enter last name.'),
  address: string().required('Please fill out address.')
})

const schema = object({
  email: string().required('Email is required.').matches(/@[^.]*\./, 'Please enter valid email.'),
  phone: string().required('Please enter your phone.').matches(/^(\+1|1)?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Please enter a valid US phone number.'),
  agree: boolean().oneOf([true], 'You must agree to the terms.')
})

type Schema = InferType<typeof schema>;
const zipError = 'Sorry, this zip code is outside our service area'
const state = reactive({
  zipError: '',
  error: false,
  errorMessage: 'Error encountered trying to submit the form.',
  loading: false,
  page: 0,
  city: '',
  state: '',
  zip: '',
  roofType: 'Asphalt',
  firstName: undefined,
  lastName: undefined,
  address: undefined,
  email: undefined,
  phone: '',
  agree: false
})

if (zip) {
  const { data, error } = await useFetch('/api/zipcode', {
    params: {
      zip
    }
  })
  if (error.value) {
    state.page = 0
  }
  if (data.value) {
    state.page = 1
    state.zip = zip
    state.city = data.value.city
    state.state = data.value.state
  }
}

function onZipNumberKeypress (event: KeyboardEvent) {
  state.zipError = ''
  const char = event.key
  const input = event.target
  const isAllSelected = input.selectionStart === 0 && input.selectionEnd === input.value.length
  if (!/\d/.test(char) || (validateZipOnSubmit() && !isAllSelected)) {
    event.preventDefault()
  }
}

function onPhoneNumberKeypress (event: KeyboardEvent) {
  const digits = state.phone?.replace(/\D/g, '')
  const validChars = '+().- 0123456789'.split('')
  const input = event.target
  const isAllSelected = input.selectionStart === 0 && input.selectionEnd === input.value.length
  if (!validChars.includes(event.key) || (digits?.length === 11 && !isAllSelected)) {
    event.preventDefault()
  }
}

function onAddressKeyPress (event: KeyboardEvent) {
  state.zipError = ''
}

const validateZipOnSubmit = (): boolean => {
  const digits = state.zip.replace(/\D/g, '')
  if (digits.length < 5) {
    return false
  }
  return true
}

async function onSubmitZip () {
  try {
    state.zipError = ''
    state.loading = true

    if (!state.zip) {
      state.loading = false
      return
    }

    // Perform client-side validation
    if (!validateZipOnSubmit()) {
      state.zipError = 'Please enter valid zip.'

      return
    }

    useTrackEvent('submit_zip', {
      event_category: 'request_quote',
      event_label: 'zip',
      value: state.zip
    })

    const { data, error } = await useFetch('/api/zipcode', {
      params: {
        zip: state.zip
      }
    })

    if (error.value) {
      state.zipError = zipError
    } else {
      // TODO: Implemet A/B test
      const abTestForm = 'redirect'
      if (abTestForm === 'redirect' && route.path !== '/request-quote') {
        navigateTo({
          path: '/request-quote',
          query: {
            zip: state.zip
          }
        })
      } else { // abTestForm === 'inline'
        state.city = data.value.city
        state.state = data.value.state
        state.page = 1
        state.error = false
      }
    }
  } catch (err) {
  } finally {
    state.loading = false
  }
}

const validateZip = () => {
  if (!validZipCodes.includes(state.zip)) {
    state.zipError = zipError
  }
}

async function onNext () {
  if (state.page === 1) {
    await validateZip()
    if (state.zipError) {
      return
    }
  }
  useTrackEvent('next_page', {
    event_category: 'request_quote',
    event_label: 'page',
    value: state.page
  })
  if (state.page < 3) {
    state.page++
  } else {
    await onSubmit()
  }
}

async function onSubmit () {
  if (state.loading) {
    return
  }
  try {
    state.loading = true
    const creationSource = window.location.href

    await $fetch('/api/request-quote', {
      method: 'POST',
      body: {
        fullName: `${state.firstName} ${state.lastName}`,
        streetAddress: state.address,
        zip: state.zip,
        city: state.city,
        state: state.state,
        email: state.email,
        phoneNumber: state.phone,
        agree: state.agree,
        roofType: state.roofType,
        creationSource
      }
    })
    await navigateTo({
      path: '/thank-you'
    })
  } catch (error) {
    state.error = true
    state.errorMessage = error.message
    useTimeoutFn(() => {
      state.error = false
      state.errorMessage = ''
    }, 5000)

    useTrackEvent('submit_form', {
      event_category: 'request_quote',
      event_label: 'error',
      value: 1
    })
  } finally {
    state.loading = false
  }
}

const addressFields = reactive({
  streetAddress: state.address
})

const handleAddressUpdate = async (newAddressFields: object) => {
  state.address = newAddressFields.streetAddress
  state.city = newAddressFields.city ?? state.city
  state.zip = newAddressFields.zip ?? state.zip
  state.state = newAddressFields.state ?? state.state
  if (state.address && (newAddressFields.city || newAddressFields.zip || newAddressFields.state)) {
    addressFields.streetAddress = state.address
    try {
      await form.value.validate('address')
    } catch (e) {
    }
  }
}

</script>
